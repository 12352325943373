main {
  display: flex;
  justify-content: center;
}

table {
  max-width: 100%;
  border-collapse: collapse;
}

tr:nth-child(odd) {
  background-color: #eee;
}

th {
  background-color: $text-color;
  color: #fff;
}

th.bottom-border {
  border-bottom: 1pt solid white;
}

th,
td {
  text-align: center;
  vertical-align: middle;
  padding: 0.5em 1em;
}
