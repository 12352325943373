.contact-section {
  margin-top: 50px;
  margin-bottom: 150px;

  h2 {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    color: $text-color-dark;
  }

  p {
    font-size: 22px;
    font-weight: normal;
    font-family: $secondary-font;
    text-align: center;
    color: $text-color-dark;
  }

}
