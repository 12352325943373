/* Project list*/
.card-lg {
  flex-direction: row-reverse;
  align-items: center;
  @include desktop {
    flex-direction: column;
  }
}

.card-body {
  h4.card-title {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 0px;
  }
}

blockquote > p {
  padding: 10px 20px;
  border-left: 3px solid $primary-color;
  background: $light-gray;
  font-size: 22px;
}

blockquote.info > p {
  padding: 10px 20px;
  background: $gray;
  border-left: 3px solid $primary-color;
  font-style: italic;
  font-size: 22px;
}

.content {
  * {
    margin-bottom: 20px;
  }

  strong {
    font-family: $secondary-font;
    font-size: 22px;
    font-weight: normal;
    color: $text-color-dark;
    display: inherit;
    line-height: 1.5;
  }
}
/* Each project page */
.project-section {
  margin-top: 120px;

  .project-title {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    color: $text-color-dark;
  }

  .project-subtitle {
    font-size: 15px;
    text-align: center;
    color: $text-color-dark;
  }

  .tag-list {
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;

    p {
      font-size: 12px;
      font-family: $tertiary-font;
      color: $text-color;
    }
  }

  .badge-list {
    text-align: center;
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .project-content {
    margin-top: 25px;

    p:first-child {
      margin-top: 0 !important;
    }

    p:last-child {
      margin-bottom: 50px !important;
    }

    p {
      font-size: 18px;
      font-weight: 300;
      font-family: $secondary-font;
      color: $text-color;
      margin-top: 25px;
    }

    b,
    strong {
      font-weight: 600;
    }

    ol {
      list-style: decimal;
      font-family: $secondary-font;
      font-size: 18px;
      line-height: 200%;
    }

    ul {
      list-style: initial;
      font-family: $secondary-font;
      font-size: 18px;
      line-height: 200%;
    }

    sup a {
      font-size: 14px;
    }

    :target {
      padding-top: 224px;
      margin-top: -224px;
      // display: inline-block;
    }

    figcaption {
      font-size: 13px;
      font-weight: 400;
      font-family: $secondary-font;
      color: $text-color;
    }

    .footnotes {
      border-top: 1px solid #ccc;
      padding-top: 30px;
    }

    .footnotes ol li:not(:last-child) p {
      margin-bottom: 10px !important;
    }

    .lettered-list-upper {
      list-style-type: upper-alpha;
    }

    .lettered-list-lower {
      list-style-type: lower-alpha;
    }

    .h1,
    h1 {
      margin-top: 50px;
      font-size: 28px;
      @include desktop {
        font-size: 28px;
      }
    }

    .h2,
    h2 {
      margin-top: 50px;
      font-size: 24px;
      @include desktop {
        font-size: 24px;
      }
    }

    .h3,
    h3 {
      margin-top: 50px;
      font-size: 23px;
      @include desktop {
        font-size: 23px;
      }
    }

    .h4,
    h4 {
      font-size: 16px;
      @include desktop {
        font-size: 16px;
      }
    }
  }
}
