.about-section {
  padding-top: 60px;
  padding-bottom: 40px;

  &-title {
    font-size: 30px;
    font-family: $tertiary-font;
    color: $text-color-dark;
    font-weight: bold;
    margin-bottom: 30px;
  }
}
