// Color Variables
$primary-color: #1A232C;
$secondary-color: #2bfdff;
$text-color: #555;
$text-color-dark: #333;
$text-color-light: #999999;
$body-color: #fff;
$border-color: #c7c7c7;
$black: #000;
$white: #fff;
$gray: #eaeaea;
$light-gray: #f9f9f9;
$sec_arrow_color: #4B5664;

// Sizes
$cut: 768px;

// Font Variables
$primary-font: 'Roboto', sans-serif;
// $secondary-font: 'Playfair Display', serif;
$secondary-font: 'Montserrat', sans-serif;
$tertiary-font: 'BwNistaInt-xBd';
$icon-font: 'themify';
