/* =============================================================================
   HTML5 CSS Reset Minified - Eric Meyer
   ========================================================================== */

html{
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body{
  font:normal 75% Arial, Helvetica, sans-serif;
}

canvas{
  display:block;
  vertical-align:bottom;
}

/* ---- particles.js container ---- */

#particles-js{
  width: 100%;
  height: 100%;
  background-color: $primary-color;
  background-image: url('');
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
