@import 'variables';

@import 'mixins';

@import 'typography';

@import 'buttons';

@import 'common';

@import 'templates/navigation';

@import 'templates/hero-area';

@import 'templates/homepage';

@import 'templates/project';

@import 'templates/about';

@import 'templates/footer';

@import 'templates/portfolio';

@import 'templates/contact';

@import 'particles/particles';

@import 'animation';

@import "code-highlight/code.scss";

@import "code-highlight-theme/monokai.css";

@import "simple_table";
