.highlight {
  margin-top: 15px;
  margin-bottom: 15px;
}

pre,
code {
    font-size: 15px;
    border-radius: 3px;
}

code {
    padding: 1px 5px;
}

.python.highlighter-rouge{
  color: $text-color;
  font-weight: bold;
}

.terminal.highlighter-rouge{
  color: $text-color;
  font-weight: bold;
}

pre {
  padding: 8px 12px;

  > code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
  }

}

/* code highlight scrollbar */
pre::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

pre::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(0,0,0,0);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

pre::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: $text-color;
  height: 3px;
}

pre::-webkit-scrollbar-corner {
  display: none;
  height: 0px;
  width: 0px;
}
