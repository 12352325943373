.page-title {
  padding: 120px 0 50px;

  .container {
    position: relative;
    z-index: 1;
  }

  .bg-shape-1 {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }

  .bg-shape-2 {
    position: absolute;
    left: -20px;
    top: -10px;
  }

  .bg-shape-3 {
    position: absolute;
    left: 10%;
    top: 100px;
  }

  .bg-shape-4 {
    position: absolute;
    left: 50%;
    top: -20px;
  }

  .bg-shape-5 {
    position: absolute;
    left: 90px;
    bottom: -50px;
    transform: rotate(180deg);
  }

  .bg-shape-6 {
    position: absolute;
    right: 20%;
    bottom: -20px;
  }

  .bg-shape-7 {
    position: absolute;
    right: -220px;
    bottom: -100px;
  }
}

.filter-controls {
  li {
    cursor: pointer;

    &.active {
      font-weight: bold;
    }
  }
}
