@-webkit-keyframes pulse {
    from {
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
        filter: alpha(opacity=40);
        opacity: .4;
        top: 0;
    }
    50% {
        -ms-filter: none;
        -webkit-filter: none;
        filter: none;
        opacity: 1;
        top: -10px;
    }
    to {
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
        filter: alpha(opacity=40);
        opacity: .4;
        top: 0;
    }
}

@keyframes pulse {
    from {
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
        filter: alpha(opacity=40);
        opacity: .4;
        top: 0
    }
    50% {
        -ms-filter: none;
        -webkit-filter: none;
        filter: none;
        opacity: 1;
        top: -10px
    }
    to {
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
        filter: alpha(opacity=40);
        opacity: .4;
        top: 0
    }
}
