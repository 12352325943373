/* footer */
.footer-section {
  height: auto;
}

.section-on-footer {
  padding-top: 50px;
  padding-bottom: 30px;
  /*margin-bottom: -250px;*/
}

.shadow-down {
  position: relative;

  &::before {
    position: absolute;
    content: "";
    box-shadow: 0px 0px 80.75px 14.25px rgba(224, 241, 255, 0.34);
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: -1;
  }
}

/* /footer */
